import { FbmOrderStatusEnum } from '@/enums'
import BaseSearchFilter from './BaseSearchFilter'

class FbmStoreFilter extends BaseSearchFilter {
  /** @type {string} */
  #storeName = ''

  /** @type {string} */
  #orderStatus = ''

  constructor() {
    super()
    this.#storeName = this.storeName
  }

  setDefaultFilters() {
    this.accountNo = null
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.storeName = ''
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.orderStatus = FbmOrderStatusEnum.AWAITING.key
    this.q = ''
  }

  /**
   * The complete UploadedBatchFilter get filters.
   * @typedef {Object} filters
   * @property {number} accountNo - Account Number.
   * @property {number} companyId - Company Id.
   * @property {number} storeId - Store Id.
   * @property {number} warehouseId - Store Id.
   * @property {any} beginDate - Begin date.
   * @property {any} endDate - End date.
   * @property {string} tenantType - Tenant Type.
   * @property {string} q - Query.
   * @property {string} storeName - Status.
   */

  /** @returns {filters} {@link filters} objects */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      storeName: this.storeName,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      orderStatus: this.orderStatus,
      q: this.q,
    }
    return filter
  }

  /** @returns {string} */
  get storeName() {
    return this.#storeName
  }

  /** @param {string} value */
  set storeName(value) {
    this.#storeName = value
  }
}

export default new FbmStoreFilter()
