import {
  ref, reactive, watch, computed,
} from '@vue/composition-api'
import fbmService from '@/http/requests/fbm/fbmService'
// import fbmItemReservation from '@/http/requests/fbm/fbmItemReservation'
import FbmStoreFilter from '@/http/models/search-filters/FbmStoreFilter'
import prepMaterialService from '@/http/requests/prep-material/prepMaterialService'
import Pager from '@core/dev-extreme/utils/pager'

const currentOrder = reactive({
  customer: {},
  shippingAddress: {},
  id: 0,
  id2: null,
  requestedShippingMethod: '',
  orderTotals: {},
  fbmResourceType: '',
  companyId: 0,
  companyName: '',
  shipFromId: null,
  storeId: 0,
  fulfillmentStatus: '',
  commentStr: '',
  international: false,
  preventAutoUpdate: false,
  platform: '',
})
const orderItems = ref([])
const orderItemsForSelection = ref([])
const selectedOrderItems = ref([])
const boxes = ref([])
const orderSelected = ref(false)
const orderShippingLabels = ref([])
const carriers = ref([])
const reservedItems = ref([])
const fbmStores = ref([])
const fbmSelectedStore = ref(null)
const fbmOrderReloadNeeded = ref('')
const prepMaterials = ref([])
const preventShipmentSave = ref([])
const selectedRows = ref([])
const shippingLabelsArray = ref([])
const showCustomsDeclaration = ref(false)

export default function useFbmState() {
  const fbmOrderId = ref(null)

  async function getFbmOrder(orderId) {
    const response = await fbmService.findFbmOrderById(orderId)
    currentOrder.shippingAddress = response.shippingAddress
    currentOrder.customer = response.customer
    currentOrder.id = response.id
    currentOrder.companyId = response.companyId
    currentOrder.storeId = response.prepStoreId
    currentOrder.orderTotals = response.orderTotals
    currentOrder.requestedShippingMethod = response.requestedShippingMethod
    currentOrder.fulfillmentStatus = response.fulfillmentStatus
    currentOrder.id2 = response.id2
    currentOrder.commentStr = response.comment
    currentOrder.international = response.international
    currentOrder.preventAutoUpdate = response.preventAutoUpdate
    currentOrder.fbmResourceType = response.fbmResourceType
    currentOrder.platform = response.platform
  }

  async function getFbmOrderItems(orderId) {
    if (orderId === null) return
    const response = await fbmService.getItems(orderId)
    orderItems.value = [...response]
    orderItemsForSelection.value = [...response]
    selectedOrderItems.value = []
    boxes.value = []
  }

  async function getShippingLabels(orderId) {
    orderShippingLabels.value = []
    const response = await fbmService.getShippingLabels(orderId)
    orderShippingLabels.value = response
  }

  async function deleteShippingLabel(labelId) {
    await fbmService.deleteShippingLabel(labelId)
  }

  async function getCarrierList() {
    const response = await fbmService.getCustomCarriers()
    carriers.value = response.map(el => ({ value: el, text: el }))
  }

  async function getReservedItems(orderId) {
    const response = await fbmService.getOrderReservedItems(orderId)
    reservedItems.value = response.sort((a, b) => a.additionalItems - b.additionalItems)
  }

  async function getPrepMaterials() {
    const pager = new Pager({})
    const pageable = pager.staticPageable
    const filters = {}
    const response = await prepMaterialService.getByQuery(filters, pageable)
    prepMaterials.value = response.data.content.filter(el => el.status === 'active')
    prepMaterials.value = prepMaterials.value.map(el => ({ ...el, disabled: el.quantity === 0 && el.inventoryType === 'INVENTORY' }))
  }

  watch(
    () => fbmOrderId.value,
    async newValue => {
      try {
        // Create an array of promises
        const promises = [
          getFbmOrder(newValue),
          getFbmOrderItems(newValue),
          getShippingLabels(newValue),
          getReservedItems(newValue),
        ]

        // Wait for all promises to complete
        await Promise.all(promises)

        // All asynchronous functions have completed
        // You can perform additional actions here if needed
      } catch (error) {
        // Handle errors if any of the promises reject
        // console.error('Error:', error)
      }
    },
    { immediate: false },
  )

  async function loadFbmStores(accountNo = 0, status = 'AWAITING') {
    fbmStores.value = []
    FbmStoreFilter.setDefaultFilters()
    FbmStoreFilter.accountNo = accountNo
    FbmStoreFilter.orderStatus = status
    const filters = FbmStoreFilter.getFilters()
    const result = await fbmService.getFbmStoreSummary(filters, '')
    const all = {
      storeId: 0, storeName: 'All (Stores)', companyName: '', platform: 'all', orderCount: result.orderTotalCount,
    }
    fbmStores.value = [all, ...result.stores]
    fbmSelectedStore.value = fbmStores.value[0]?.storeId
  }

  const isCurrentOrderShipped = computed(() => currentOrder.fulfillmentStatus === 'Shipped')

  return {
    getFbmOrder,
    getFbmOrderItems,
    fbmOrderId,
    currentOrder,
    carriers,
    orderItems,
    orderItemsForSelection,
    selectedOrderItems,
    orderSelected,
    orderShippingLabels,
    boxes,
    deleteShippingLabel,
    getShippingLabels,
    reservedItems,
    getCarrierList,
    getReservedItems,
    fbmStores,
    fbmOrderReloadNeeded,
    fbmSelectedStore,
    prepMaterials,
    getPrepMaterials,
    preventShipmentSave,
    loadFbmStores,
    isCurrentOrderShipped,
    selectedRows,
    shippingLabelsArray,
    showCustomsDeclaration,
  }
}
